/* global Office, document, console, fetch */
import * as ko from "knockout";
import * as cheerio from "cheerio";
import { Modal } from "bootstrap";
import { environment } from "@env";

class TaskpaneViewModel {
  public isAddInLoading: ko.Observable<boolean>;
  public isSaving: ko.Observable<boolean>;
  public isSuccess: ko.Observable<boolean>;

  public email: ko.Observable<string>;
  public phone: ko.Observable<string>;
  public phone2: ko.Observable<string>;
  public name: ko.Observable<string>;
  public address: ko.Observable<string>;
  public city: ko.Observable<string>;
  public serviceAddressEqual: ko.Observable<boolean>;
  public serviceAddress: ko.Observable<string>;
  public serviceCity: ko.Observable<string>;
  public executor: ko.Observable<string>;
  public type: ko.Observable<string>;
  public remarks: ko.Observable<string>;

  public availableExecutors: ko.ObservableArray<string>;
  public availableTypes: ko.ObservableArray<string>;

  public attachmentUrls: string[];

  constructor() {
    this.isAddInLoading = ko.observable(true);
    this.isSaving = ko.observable(false);
    this.isSuccess = ko.observable(false);

    this.email = ko.observable("");
    this.phone = ko.observable("");
    this.phone2 = ko.observable("");
    this.name = ko.observable("");
    this.address = ko.observable("");
    this.city = ko.observable("");
    this.serviceAddressEqual = ko.observable(false);
    this.serviceAddress = ko.observable("");
    this.serviceCity = ko.observable("");
    this.executor = ko.observable("");
    this.type = ko.observable("");
    this.remarks = ko.observable("");

    this.availableExecutors = ko.observableArray(["", "Kris", "Bart"]);
    this.availableTypes = ko.observableArray(["renovatie", "nieuwbouw", "regie"]);

    this.attachmentUrls = [];

    Office.onReady(() => this.loadData());
  }

  async loadData() {
    const item = Office.context.mailbox.item;
    if (!item) return;

    const mailbox = Office.context.mailbox.userProfile.emailAddress.toLowerCase();
    if (mailbox.startsWith("kris")) {
      this.executor("Kris");
    } else if (mailbox.startsWith("bart")) {
      this.executor("Bart");
    } else {
      this.executor("");
    }

    this.email(item.from.emailAddress);
    this.name(item.from.displayName);

    try {
      const bodyHtml = await this.GetBodyHtml();
      console.log(bodyHtml);
      if (bodyHtml?.value) {
        const $ = cheerio.load(bodyHtml.value);

        const name = this.getTextValue($, "name");
        if (name) this.name(name);

        const email = this.getTextValue($, "email");
        if (email) this.email(email);

        this.phone(this.getTextValue($, "phone"));
        this.phone2(this.getTextValue($, "phone2"));
        this.address(this.getTextValue($, "contactAddress"));
        this.city(this.getTextValue($, "contactCity"));
        this.serviceAddress(this.getTextValue($, "serviceAddress"));
        this.serviceCity(this.getTextValue($, "serviceCity"));
        this.remarks(this.getTextValue($, "remarks"));

        const type = this.getTextValue($, "type");
        if (type == "Nieuwbouw") {
          this.type("nieuwbouw");
        } else if (type == "Renovatie") {
          this.type("renovatie");
        } else if (type == "Stormschade" || type == "Waterinsijpeling") {
          this.type("regie");
        }

        for (var i = 1; i <= 3; i++) {
          let attachmentEl = $("#attachment" + i);
          if (!attachmentEl.length) {
            attachmentEl = $("#x_attachment" + i);
          }
          if (attachmentEl.length > 0) {
            const attachmentUrl = $(attachmentEl).find("a:first")?.text();
            if (attachmentUrl) {
              this.attachmentUrls.push(attachmentUrl);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    this.isAddInLoading(false);
  }

  private getTextValue($: cheerio.CheerioAPI, elId: string) {
    const el = $(`#${elId}`);
    if (el.length > 0) return el.text().trim();
    return $(`#x_${elId}`).text();
  }

  private async GetBodyHtml() {
    try {
      return await new Promise<Office.AsyncResult<string>>((resolve, reject) => {
        Office.context.mailbox.item!.body.getAsync(Office.CoercionType.Html, (asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult);
          } else {
            console.error(asyncResult.error);
            reject(asyncResult.error);
          }
        });
      });
    } catch (error) {
      return null;
    }
  }

  async submitForm() {
    if (this.isSaving()) return;

    if (!this.type()) {
      this.showAlert("Gelieve een type te selecteren.");
      return;
    }

    try {
      this.isSaving(true);

      const item = Office.context.mailbox.item!;

      const request: CreateProjectRequest = {
        name: this.name(),
        email: this.email(),
        address: this.address(),
        city: this.city(),
        serviceAddress: this.serviceAddress(),
        serviceCity: this.serviceCity(),
        phone: this.phone(),
        phone2: this.phone2(),
        emailId: Office.context.mailbox.convertToRestId(item.itemId, Office.MailboxEnums.RestVersion.v1_0),
        mailbox: Office.context.mailbox.userProfile.emailAddress,
        subject: item.subject,
        executor: this.executor(),
        type: this.type(),
        summary: this.remarks(),
        attachmentUrls: this.attachmentUrls,
      };

      if (this.serviceAddressEqual()) {
        request.serviceAddress = request.address;
        request.serviceCity = request.city;
      }

      await fetch(`${environment.baseApiUrl}/api/project/CreateFromOutlook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
        mode: "cors",
      });

      this.isSuccess(true);
    } catch (error) {
      this.showAlert("Er is een fout opgetreden bij het opslaan van het project.", error);
    } finally {
      this.isSaving(false);
    }
  }

  private showAlert(msg: string, error?: any) {
    document.getElementById("alertModalBody")!.innerText = msg;
    if (error) {
      document.getElementById("alertModalBody")!.innerText += "<br/><br/><pre>" + error + "</pre>";
    }
    const modal = new Modal(document.getElementById("alertModal")!);
    modal.show();
  }
}

ko.applyBindings(new TaskpaneViewModel());

interface CreateProjectRequest {
  name: string;
  email: string;
  address: string;
  city: string;
  serviceAddress: string;
  serviceCity: string;
  phone: string;
  phone2: string;
  emailId: string;
  mailbox: string;
  subject: string;
  executor: string;
  type: string;
  summary: string;
  attachmentUrls: string[];
}
